<template>
  <div>
    <form class="columns is-multiline" @submit="onNextStep">
      <div class="column is-12">
        <div class="box">
          <div class="columns is-multiline">
            <div class="column is-12 is-paddingless">
              <h1>Escribe sobre el producto</h1>
            </div>
            <div class="column is-6">
              <div class="field">
                <label class="label">Título</label>
                <div class="control">
                  <input
                    class="input"
                    type="text"
                    v-model="title"
                    placeholder="iPhone/Samsung en buen estado"
                    name="title"
                    :class="{ 'is-danger': vverrors.first('title') }"
                    v-validate="'required'"
                  >
                </div>
              </div>
            </div>
            <div class="column is-12">
              <div class="field">
                <label class="label">Descripción</label>
                <div class="control">
                  <textarea
                    class="input text-area-height"
                    type="text"
                    v-model="description"
                    placeholder="Descripción del smartphone o dispositivo que vas a vender. Entre más atractivo, mucho mejor."
                    name="description"
                    :class="{ 'is-danger': vverrors.first('description') }"
                    v-validate="'required'"
                  ></textarea>
                </div>
              </div>
            </div>
            <div class="column is-12 is-paddingless">
              <h1>Categoría</h1>
            </div>
            <template v-for="(category, index) of filteredCategoriesList">
              <div class="column is-2" :key= "index">
                <div class="list-category">
                  <label class="label">{{category.categoryFather}}</label>
                  <template v-for="(item, ind) of category.categoriesList">
                    <div class="category" :key="ind" v-if="item.id === 12">
                      <label class="checkbox" :class="{'has-text-danger': isErrorCatagories}">
                        <input
                          type="checkbox"
                          @click="addCategory(item.id)"
                          :checked="categoriesSelected.find(option => option === item.id)"
                        >
                        {{item.name}}
                      </label>
                    </div>
                  </template>
                </div>
              </div>
            </template>
            <div class="column is-12 is-paddingless">
              <h1>Marca y Modelo</h1>
            </div>

            <div class="column is-6">
              <div class="field">
                <label class="label">Marca</label>
                <div class="select is-fullwidth" :class="{ 'is-danger': vverrors.first('brand') }">
                  <select name="brand" v-model="brand" v-validate="'required|excluded:-1'" @change="model = null">
                    <option :value="null" disabled>Seleccione una opción</option>
                    <option :value="1">Samsung</option>
                    <option :value="2">iPhone</option>
                  </select>
                </div>
              </div>
            </div>

            <div class="column is-6">
              <div class="field">
                <label class="label">Modelo</label>
                <div class="select is-fullwidth" :class="{ 'is-danger': vverrors.first('model') }">
                  <select name="model" v-model="model" v-validate="'required|excluded:-1'">
                    <option :value="null" disabled>Seleccione una opción</option>
                    <option v-for="modelObj in filteredModels" :value="modelObj.id" :key="modelObj.id">
                      {{ modelObj.name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="column is-11 is-paddingless">
              <h1>Características</h1>
            </div>
            <template v-for="(characteristic) of characteristicsSelected">
              <div class="column is-4" :key="characteristic.name">
                <div class="field">
                  <label class="label">{{characteristic.name}}</label>
                  <div class="control">
                    <input
                      class="input"
                      type="text"
                      :value="characteristic.value"
                      :name="characteristic.name"
                      @input="setValueCharacteristic($event.target)"
                      :class="{ 'is-danger': vverrors.first(characteristic.name) }"
                      v-validate="'required'"
                    >
                  </div>
                </div>
              </div>
            </template>
            <div class="column is-12 is-paddingless">
              <h1>Valor estimado</h1>
            </div>
            <div class="column is-6">
              <div class="field">
                <label class="label">Precio</label>
                <div class="control">
                  <input
                    class="input"
                    type="number"
                    v-model="price"
                    placeholder="0.00"
                    name="price"
                    :class="{ 'is-danger': vverrors.first('price') }"
                    v-validate="'required|regex:^[0-9]+'"
                  >
                </div>
              </div>
              <!-- <p>Recuerda que cada GIV equivale a $1 MXN.</p> -->
            </div>
            <div class="column is-6">
              <div class="field">
                <label class="label">Condición</label>
                <div class="select is-fullwidth" :class="{ 'is-danger': vverrors.first('condition') }">
                  <select
                    name="condition"
                    v-model="condition"
                    v-validate="'required|excluded:-1'"
                  >
                    <option :value="null" disabled>Seleccione una opción</option>
                    <option :value="1"> Nuevo</option>
                    <option :value="2"> Seminuevo</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="column is-6">
              <div class="field">
                <label class="label">Lugar de deposito</label>
                <div class="select is-fullwidth" :class="{ 'is-danger': vverrors.first('placeDeposit') }">
                  <select
                    name="placeDeposit"
                    v-model="placeDeposit"
                    v-validate="'required|excluded:-1'"
                  >
                    <option :value="null" disabled>Seleccione una opción</option>
                    <option :value="1"> DHL</option>
                    <option :value="2"> WeWork Montes Úrales</option>
                    <option :value="2"> German Centre  Av. Santa Fe 170</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="column is-6">
              <div class="field">
                <label class="label">Stock</label>
                <div class="control">
                  <input
                    class="input"
                    type="number"
                    v-model="stock"
                    placeholder="1"
                    name="stock"
                    :class="{ 'is-danger': vverrors.first('stock') }"
                    v-validate="'required|regex:^[0-9]+'"
                  >
                </div>
              </div>
            </div>
            <div class="column is-12 is-paddingless">
              <h1>Extras</h1>
            </div>
            <div class="column is-12">
              <div class="field">
                <label class="label">¿Cuenta con caja original?</label>
                <div class="select is-fullwidth" :class="{ 'is-danger': vverrors.first('box') }">
                  <select
                    name="box"
                    v-model="box"
                    v-validate="'required|excluded:-1'"
                  >
                    <option :value="null" disabled>Seleccione una opción</option>
                    <option :value="true"> Si</option>
                    <option :value="false"> No</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="column is-12">
              <div class="field">
                <label class="label">¿Cuenta con accesorios originales(cargador y cable)?</label>
                <div class="select is-fullwidth" :class="{ 'is-danger': vverrors.first('accessories') }">
                  <select
                    name="accessories"
                    v-model="accessories"
                    v-validate="'required|excluded:-1'"
                  >
                    <option :value="null" disabled>Seleccione una opción</option>
                    <option :value="true"> Si</option>
                    <option :value="false"> No</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-12">
        <div class="columns is-multiline is-pulled-centered padding-top-20">
          <div class="column is-12 has-text-right">
            <button
              class="button button-givu"
              type="submit"
            >
              Continuar
            </button>
          </div>
        </div>
      </div>
    </form>
    <modal-add-characteristic
      v-if="modalAddCharacteristic"
      :close="showModalAddChatacteristic"
      :catalogCharacteristicsList="catalogCharacteristicsList"
      :characteristicsSelected="characteristicsSelected"
      :saveCharacteristic="saveCharacteristic"
    />
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'

export default {
  name: 'General',
  components: {
    modalAddCharacteristic: () => import('@/components/NewProduct/ModalAddCharacteristic.vue')
  },
  mounted () {
    this.title = this._title
    this.description = this._description
    this.price = this._price
    this.condition = this._condition
    this.placeDeposit = this._placeDeposit
    this.stock = this._stock
    this.box = this._box
    this.accessories = this._accessories
    this.brand = this._brand
    this.model = this._model
    this.categoriesSelected = this._categoriesSelected
    this.characteristicsSelected = this.characteristicsSelected
  },
  data () {
    return {
      title: '',
      description: '',
      price: '',
      condition: null,
      placeDeposit: null,
      stock: '',
      box: null,
      accessories: null,
      brand: 0,
      model: 0,
      catalogCategoriesList: [],
      categoriesSelected: [],
      catalogCharacteristicsList: [],
      characteristicsSelected: [
        {
          'id': 3,
          'name': 'Tamaño / Capacidad'
        },
        {
          'id': 1,
          'name': 'Color'
        }
      ],
      modalAddCharacteristic: false,
      isErrorCatagories: false,
      isErrorCharacteristics: false,
      isLoading: true,
      'models': [
        {
          'id': 1,
          'name': 'X',
          'brand_id': 2,
          'created_at': '2023-10-06T20:13:48.427Z',
          'updated_at': '2023-10-06T20:13:48.427Z',
          'active': true
        },
        {
          'id': 2,
          'name': 'XR',
          'brand_id': 2,
          'created_at': '2023-10-06T20:13:48.427Z',
          'updated_at': '2023-10-06T20:13:48.427Z',
          'active': true
        },
        {
          'id': 3,
          'name': 'XS',
          'brand_id': 2,
          'created_at': '2023-10-06T20:13:48.427Z',
          'updated_at': '2023-10-06T20:13:48.427Z',
          'active': true
        },
        {
          'id': 4,
          'name': 'XS Max',
          'brand_id': 2,
          'created_at': '2023-10-06T20:13:48.427Z',
          'updated_at': '2023-10-06T20:13:48.427Z',
          'active': true
        },
        {
          'id': 5,
          'name': '11',
          'brand_id': 2,
          'created_at': '2023-10-06T20:13:48.427Z',
          'updated_at': '2023-10-06T20:13:48.427Z',
          'active': true
        },
        {
          'id': 6,
          'name': '11 Pro',
          'brand_id': 2,
          'created_at': '2023-10-06T20:13:48.427Z',
          'updated_at': '2023-10-06T20:13:48.427Z',
          'active': true
        },
        {
          'id': 7,
          'name': '11 Pro Max',
          'brand_id': 2,
          'created_at': '2023-10-06T20:13:48.427Z',
          'updated_at': '2023-10-06T20:13:48.427Z',
          'active': true
        },
        {
          'id': 8,
          'name': 'SE (2nd generation)',
          'brand_id': 2,
          'created_at': '2023-10-06T20:13:48.427Z',
          'updated_at': '2023-10-06T20:13:48.427Z',
          'active': true
        },
        {
          'id': 9,
          'name': '12 Mini',
          'brand_id': 2,
          'created_at': '2023-10-06T20:13:48.427Z',
          'updated_at': '2023-10-06T20:13:48.427Z',
          'active': true
        },
        {
          'id': 10,
          'name': '12',
          'brand_id': 2,
          'created_at': '2023-10-06T20:13:48.427Z',
          'updated_at': '2023-10-06T20:13:48.427Z',
          'active': true
        },
        {
          'id': 11,
          'name': '12 Pro',
          'brand_id': 2,
          'created_at': '2023-10-06T20:13:48.427Z',
          'updated_at': '2023-10-06T20:13:48.427Z',
          'active': true
        },
        {
          'id': 12,
          'name': '12 Pro Max',
          'brand_id': 2,
          'created_at': '2023-10-06T20:13:48.427Z',
          'updated_at': '2023-10-06T20:13:48.427Z',
          'active': true
        },
        {
          'id': 13,
          'name': '13 Mini',
          'brand_id': 2,
          'created_at': '2023-10-06T20:13:48.427Z',
          'updated_at': '2023-10-06T20:13:48.427Z',
          'active': true
        },
        {
          'id': 14,
          'name': '13',
          'brand_id': 2,
          'created_at': '2023-10-06T20:13:48.427Z',
          'updated_at': '2023-10-06T20:13:48.427Z',
          'active': true
        },
        {
          'id': 15,
          'name': '13 Pro',
          'brand_id': 2,
          'created_at': '2023-10-06T20:13:48.427Z',
          'updated_at': '2023-10-06T20:13:48.427Z',
          'active': true
        },
        {
          'id': 16,
          'name': '13 Pro Max',
          'brand_id': 2,
          'created_at': '2023-10-06T20:13:48.427Z',
          'updated_at': '2023-10-06T20:13:48.427Z',
          'active': true
        },
        {
          'id': 17,
          'name': '14 Mini',
          'brand_id': 2,
          'created_at': '2023-10-06T20:13:48.427Z',
          'updated_at': '2023-10-06T20:13:48.427Z',
          'active': true
        },
        {
          'id': 18,
          'name': '14',
          'brand_id': 2,
          'created_at': '2023-10-06T20:13:48.427Z',
          'updated_at': '2023-10-06T20:13:48.427Z',
          'active': true
        },
        {
          'id': 19,
          'name': '14 Pro',
          'brand_id': 2,
          'created_at': '2023-10-06T20:13:48.427Z',
          'updated_at': '2023-10-06T20:13:48.427Z',
          'active': true
        },
        {
          'id': 20,
          'name': '14 Pro Max',
          'brand_id': 2,
          'created_at': '2023-10-06T20:13:48.427Z',
          'updated_at': '2023-10-06T20:13:48.427Z',
          'active': true
        },
        {
          'id': 30,
          'name': '15',
          'brand_id': 2,
          'created_at': '2023-10-06T20:13:48.427Z',
          'updated_at': '2023-10-06T20:13:48.427Z',
          'active': true
        },
        {
          'id': 31,
          'name': '15 mini',
          'brand_id': 2,
          'created_at': '2023-10-06T20:13:48.427Z',
          'updated_at': '2023-10-06T20:13:48.427Z',
          'active': true
        },
        {
          'id': 32,
          'name': '15 Pro',
          'brand_id': 2,
          'created_at': '2023-10-06T20:13:48.427Z',
          'updated_at': '2023-10-06T20:13:48.427Z',
          'active': true
        },
        {
          'id': 33,
          'name': '15 Pro Max',
          'brand_id': 2,
          'created_at': '2023-10-06T20:13:48.427Z',
          'updated_at': '2023-10-06T20:13:48.427Z',
          'active': true
        },
        {
          'id': 21,
          'name': 'Galaxy S22 Ultra',
          'brand_id': 1,
          'created_at': '2023-10-06T20:16:25.200Z',
          'updated_at': '2023-10-06T20:16:25.200Z',
          'active': true
        },
        {
          'id': 22,
          'name': 'Galaxy S22+',
          'brand_id': 1,
          'created_at': '2023-10-06T20:16:25.200Z',
          'updated_at': '2023-10-06T20:16:25.200Z',
          'active': true
        },
        {
          'id': 23,
          'name': 'Galaxy S22',
          'brand_id': 1,
          'created_at': '2023-10-06T20:16:25.200Z',
          'updated_at': '2023-10-06T20:16:25.200Z',
          'active': true
        },
        {
          'id': 24,
          'name': 'Galaxy Z Fold3',
          'brand_id': 1,
          'created_at': '2023-10-06T20:16:25.200Z',
          'updated_at': '2023-10-06T20:16:25.200Z',
          'active': true
        },
        {
          'id': 25,
          'name': 'Galaxy Z Flip3',
          'brand_id': 1,
          'created_at': '2023-10-06T20:16:25.200Z',
          'updated_at': '2023-10-06T20:16:25.200Z',
          'active': true
        },
        {
          'id': 26,
          'name': 'Galaxy A52 5G',
          'brand_id': 1,
          'created_at': '2023-10-06T20:16:25.200Z',
          'updated_at': '2023-10-06T20:16:25.200Z',
          'active': true
        },
        {
          'id': 27,
          'name': 'Galaxy A32 5G',
          'brand_id': 1,
          'created_at': '2023-10-06T20:16:25.200Z',
          'updated_at': '2023-10-06T20:16:25.200Z',
          'active': true
        },
        {
          'id': 28,
          'name': 'Galaxy A12',
          'brand_id': 1,
          'created_at': '2023-10-06T20:16:25.200Z',
          'updated_at': '2023-10-06T20:16:25.200Z',
          'active': true
        },
        {
          'id': 29,
          'name': 'Galaxy A02s',
          'brand_id': 1,
          'created_at': '2023-10-06T20:16:25.200Z',
          'updated_at': '2023-10-06T20:16:25.200Z',
          'active': true
        }
      ]
    }
  },
  async beforeMount () {
    let responseCategories = await this.getCategoriesProduct()
    let responseCharacteristics = await this.getCharacteristicsProduct()
    this.catalogCategoriesList = responseCategories
    this.catalogCharacteristicsList = responseCharacteristics
    this.isLoading = false
    console.log(this.catalogCharacteristicsList, 'categorias')
  },
  computed: {
    ...mapState({
      _title: state => state.newProduct.title,
      _description: state => state.newProduct.description,
      _price: state => state.newProduct.price,
      _condition: state => state.newProduct.condition,
      _placeDeposit: state => state.newProduct.placeDeposit,
      _stock: state => state.newProduct.stock,
      _box: state => state.newProduct.box,
      _accessories: state => state.newProduct.accessories,
      _model: state => state.newProduct.model,
      _brand: state => state.newProduct.brand,
      _categoriesSelected: state => state.newProduct.categoriesSelected,
      _characteristicsSelected: state => state.newProduct.characteristicsSelected
    }),
    filteredCategoriesList () {
      return this.catalogCategoriesList.filter(this.categoryContainsId12)
    },
    filteredModels () {
      return this.models.filter(modelObj => modelObj.brand_id === this.brand)
    }
  },
  methods: {
    ...mapMutations(['updateStepNewProduct', 'setNewProductValues']),
    ...mapActions(['getCategoriesProduct', 'getCharacteristicsProduct']),
    categoryContainsId12 (category) {
      return category.categoriesList.some(item => item.id === 12)
    },
    addCategory (id) {
      let isExist = this.categoriesSelected.find(item => item === id)
      if (isExist) {
        let removeCategory = this.categoriesSelected.filter(item => item !== id)
        this.categoriesSelected = removeCategory
      } else {
        this.categoriesSelected = [...this.categoriesSelected, id]
      }
      this.isErrorCatagories = false
    },
    showModalAddChatacteristic () {
      this.modalAddCharacteristic = !this.modalAddCharacteristic
      this.isErrorCharacteristics = false
    },
    saveCharacteristic (characteristic) {
      this.characteristicsSelected = characteristic
      this.showModalAddChatacteristic()
    },
    setValueCharacteristic (target, name) {
      this.characteristicsSelected = this.characteristicsSelected.map(item => {
        if (item.name === target.name) item.value = target.value
        return item
      })
    },
    onStepBack () {
      this.updateStepNewProduct(1)
    },
    async onNextStep (e) {
      e.preventDefault()
      console.log(this.placeDeposit)
      if (await this.$validator.validateAll()) {
        this.setNewProductValues({
          title: this.title,
          description: this.description,
          model: this.model,
          brand: this.brand,
          price: this.price,
          box: this.box,
          accessories: this.accessories,
          condition: this.condition,
          placeDeposit: this.placeDeposit,
          stock: this.stock,
          categoriesSelected: this.categoriesSelected,
          characteristicsSelected: this.characteristicsSelected
        })
        if (!this.categoriesSelected.length || !this.characteristicsSelected.length) {
          if (!this.categoriesSelected.length) this.isErrorCatagories = true
          if (!this.characteristicsSelected.length) this.isErrorCharacteristics = true
          return true
        }
        this.updateStepNewProduct(2)
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .text-area-height {
    height: 100px;
  }
  h1 {
    border-bottom: 1px solid #cd3d27;
    padding-bottom: 0.3rem;
    margin-bottom: 0.5rem;
  }
  .list-category {
    border-radius: 5px;
    width: 120%;
    border: 1px solid #292A30;
    padding: 0;
    .label {
      padding: 0.4rem;
      font-size: 12px;
      text-align: center;
      background: #292A30;
      color: #fff;
      border-bottom: #292A30 1px solid;
    }
    .category {
      padding: 0.3rem;
      font-size: 12px;
    }
  }
</style>
